* {
    box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #272727;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

body {
    position: fixed;
    overflow: hidden;
    overscroll-behavior-y: none;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
    color: black;
}

.main {
    position: relative;
    width: 100%;
    height: 100%;
    color: white;
    overflow: hidden;
}

  
li {
    list-style: none;
}

a {
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
    position: absolute;
    font-size: 1.1em;
    display: inline;
    text-decoration: none;
}




a.top-left {
    top: 50px;
    left: 50px;
}

a.top-right {
    top: 50px;
    right: 50px;
}

a.bottom-left {
    bottom: 50px;
    left: 50px;
}

a.bottom-right {
    bottom: 50px;
    right: 50px;
}

canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
    overflow: hidden;
}

.scroll-container {
    position: absolute;
    overflow: auto;
    top: 0px;
    width: 100%;
    height: 100vh;
    font-size: 20em;
    font-weight: 800;
    line-height: 0.9em;
}
  
.grid {
    display: flex;
    flex-wrap: wrap;
}
  
.grid .item {
    position: relative;
    width: 50vw;
    height: 50vw;
    background: #eee;
}

@media only screen and (max-width: 480px) {
    .grid .item {
      width: 100vw;
      height: 100vw;
    }
}


span.header {
    font-family: 'Roboto', sans-serif;
    position: absolute;
    display: inline-block;
    width: 500px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    left: 2em;
    font-size: 8em;
    line-height: 1em;
  }